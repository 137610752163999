import React from "react";
import styled from "styled-components";
import { Title, Box, Text } from "../components/Core";
import { device } from "../utils";
import { Col, Container, Row } from "react-bootstrap";
import youFibreTTS from "../assets/image/svg/house.svg";
import Seo from "../components/Seo/Seo";
import CheckAvailability from "../components/Rebrand/CheckAvailability/CheckAvailability";

const BoxStyled = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const BoxInner = styled(Box)`
  margin-top: -65px;
  min-height: 100vh;
`;

const BigTitle = styled(Title)`{
  font-size: 2.8em;
}`

const NormalTitle = styled(Title)`{
  font-size: 2.3em;
}`

const FormStyled = styled.div`
  padding: 40px 30px;
  @media ${device.sm} {
    padding: 53px 58px 50px;
  }
  border-radius: 10px;
  width: 100%;
`;

const LaunchPage = () => {

    return (
        <div>
            <Seo page="launch" />
            <BoxStyled bg="#f7f8fa">
                <BoxInner className="d-flex align-items-center mt-large">
                    <Container>
                        <Box mx="auto">
                            <Row className="justify-content-center">
                                <Col lg="12">
                                    <FormStyled>
                                        <Row>
                                            <Col sm={12} lg={11} className="mx-auto">
                                                <div className="mb-3 pb-2 text-center">
                                                    <img src={youFibreTTS} alt="Launch"
                                                        style={{ 'max-width': '100px', 'width': '100%' }} />
                                                    <BigTitle className="m-3 pt-3">
                                                        Yay, almost there!
                                                    </BigTitle>
                                                    <Text variant="card" className="pt-4">
                                                        <p>
                                                            We want everyone to have access to fast and affordable broadband,
                                                            so that the whole household can have a great time online.
                                                            We have been working very hard in your area and we will be able to get you connected soon.
                                                        </p>
                                                        <p className="mt-3">
                                                            <strong>Simply register your details against your property below</strong> and
                                                            we’ll be in touch as soon your area is ready to place an order,
                                                            including details of any early bird discounts.
                                                        </p>
                                                    </Text>
                                                </div>
                                            </Col>
                                        </Row>
                                    </FormStyled>
                                    <br></br>
                                    <br></br>
                                    <div className="voucher-page-wrapper" id="check-availability">
                                        <div className="launch-page-container">
                                            <CheckAvailability center focusInput={false} minimized={false} salesManager={null} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Box>
                    </Container>
                </BoxInner>
            </BoxStyled>
        </div>
    )
}

export default LaunchPage